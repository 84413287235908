import React from 'react'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import Hero from '@global/Blocks/Hero'
import MobileNavbar from '@global/MobileNavbar'
import PropertySearchFrom from '@global/PropertySearchFrom'

const HomePage = ({ pageData }) => {

  return (
    <>
      <Hero
        bgVideoUrl={pageData.header_video_url}
        title={pageData.header_title}
        bgImageUrl={pageData.header_image?.url}
        description={pageData.header_description}
        searchForm={<PropertySearchFrom />}
        fullscreen
      />
      <WagtailBlocksSection blocks={pageData.content_blocks} />
      <MobileNavbar />
    </>
  )
}

export default HomePage
